import React from "react";
import Layout from "../components/common/layout";
import Main from "../components/common/main";
import SEO from "../components/common/seo";


const intro = (
  <>
    <div className="vb-150" />
    <div className="container line-container d-flex">
      <div className="col px-0">
        <div className="row">
          <div className="col-lg-8">
            <h1 className="display-2  text-white">
              About Us
              <span>Meet your future team 👋 </span>
            </h1>
          </div>
        </div>
      </div>
    </div>
    <div className="pt-100" />
  </>
)

const body = (
  <section className="section bg-secondary">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-12">
          <div className="row row-grid">
            <div className="col-lg-12">
                <div className="row row-grid align-items-center">
                    <div className="col-md-12 order-md-1">
                      <div className="pr-md-5">
                      <p className="lead">We are a young and innovative software development agency located in Pune, India. Our expertise is to build highly scalable applications and infrastructure solutions for our partners and budding startups.</p>
                      <p className="lead">We provide end-to-end solutions ranging from cloud architecture to backend services and modern frontend development. We leverage agile methodologies, software life cycle best practices and cutting edge technology to deliver perfect solutions reflecting our passion for software development.</p>
                      <p className="lead">We care about our company culture and invest in people to create the best teams of highly skilled developers.</p>
                      </div>
                    </div>
                    <div className="col-md-12 order-md-2">
                        <img src="https://res.cloudinary.com/polyglots-cdn/image/upload/v1567714880/polyglots/site/team.png" className="img-fluid" alt="team" />
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const Company = () => (
  <Layout>
    <SEO title="Company" />
    <Main lineColor="line-primary">
        {intro}
    </Main>
    {body}
  </Layout>
)

export default Company
